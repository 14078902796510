import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { queryParamsToObject } from '../../../utils';
import { IPN_UPDATE_SUCCESS } from '../../../constants';
import * as Cart from '../../../utils/cart';
import * as paymentService from '../../../apis/payment';
import SEO from '../../../components/SEO';
import Container from '../../../components/Container';
import { MainLayout } from '../../../components/Layout';
import Success from '../../../components/Pricing/result-pages/Success';
import Fail from '../../../components/Pricing/result-pages/Fail';
import Cancel from '../../../components/Pricing/result-pages/Cancel';
import Loading from '../../../components/Loading';
import onepayFailReasons from '../../../content/pricing/onepay-international-fail-reasons';

function Result({ location }) {
  const [status, setStatus] = useState(null);
  const [reason, setReason] = useState(null);

  useEffect(async () => {
    Cart.clear();
    setStatus('ipn');

    const responseParam = queryParamsToObject(location.search);

    if (responseParam) {
      const responseCode = responseParam.vpc_TxnResponseCode;
      let response;
      try {
        response = await paymentService.ipnOnepay({ responseParam });
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }

      if (responseCode === '0' && IPN_UPDATE_SUCCESS === response.data) {
        setStatus('success');
      } else {
        setStatus(responseCode === '99' ? 'cancelled' : 'fail');
        setReason(onepayFailReasons[responseCode]);
      }
    } else {
      setStatus('fail');
    }
  }, []);

  return (
    <MainLayout>
      <SEO title="ONEPAY payment result" keywords={[`dolonline`, `pricing`]} />
      <Container>
        {status && status === 'success' && <Success />}
        {status && status === 'fail' && <Fail reason={reason} />}
        {status && status === 'cancelled' && <Cancel />}
        {status && status === 'ipn' && <Loading />}
      </Container>
    </MainLayout>
  );
}

Result.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Result;
